@import '~/styles/utils';

.nav {
  visibility: hidden;
  transition: clip-path $quick $ease-out, visibility 0s linear $quick;
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100vw;
  padding: 16rem $side-padding;
  color: $color-foreground;
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);

  &.open {
    visibility: visible;
    transition-delay: 0s;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  // nested backdrop-filter fix
  &::before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid transparent-color('lavender', 0.3);
    background: $color-header-background;
    backdrop-filter: blur(7rem);
  }

  @include landscape {
    visibility: visible;
    transition: none;
    position: static;
    width: auto;
    padding: 0;
    clip-path: none;

    &::before {
      content: none;
    }
  }
}

.content {
  @include landscape {
    display: flex;
    align-items: center;
    height: 100%;
  }
}
