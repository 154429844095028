@import '~/styles/utils';

.videoWrapper {
  position: relative;
  width: 90%;
  height: 100%;
  background: $color-background;
  transform: translate3d(0, 0, 0);
}

.playerWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 0);
  video {
    object-fit: cover;
  }
}

.controlsBackgroundOverlay {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  max-height: 310rem;
  width: 100%;
  height: 25%;
  transform: scaleY(0.25);
  transform-origin: 50% 100%;
  opacity: 0.25;
  transition: transform $speed $evil-ease, opacity $speed $evil-ease;
  background: linear-gradient(
    180deg,
    transparent-color('background', 0) 0%,
    transparent-color('background', 0.62) 100%
  );
}

.progressBar {
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  width: 100%;
  height: 6rem;
  z-index: 10;
  overflow: hidden;
  transition: transform $speed $evil-ease, left $speed $evil-ease, width $speed $evil-ease,
    border-radius $speed $evil-ease;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0.6;
    background: $color-foreground;
  }
}

.progress {
  cursor: pointer;
  font-size: 0;
  margin: 0;
  appearance: none;
  width: 100%;
  height: 6rem;
  border: none;
  display: block;
  position: relative;
  background: linear-gradient(
    -90deg,
    #{$accent-color} 0%,
    #{$accent-color} 50%,
    transparent 50%,
    transparent 100%
  );
  background-size: 200%;
  &::-webkit-slider-thumb {
    opacity: 0;
    background: transparent;
  }
  &:focus-visible {
    box-shadow: 0 0 0 1px red inset;
  }
}

.volumeControls {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 10;
  right: 40rem;
  bottom: 13rem;
  transition: transform $speed $evil-ease, opacity $speed $evil-ease;
  opacity: 0;
  // pointer-events: none;
  transform: translate(100%, 100%) scale(0);
  gap: 10rem;
  justify-content: center;
  align-items: center;
  @include md {
    bottom: 29rem;
  }
}

.muteButton {
  position: relative;
  appearance: none;
  width: 30rem;
  height: 30rem;
  border: none;
  color: $color-foreground;
  cursor: pointer;
  transition: color $speed $evil-ease;
  @include md {
    width: 50rem;
    height: 50rem;
  }
  &:hover {
    color: $accent-color;
  }
}

.volumeBar {
  position: absolute;
  top: -10rem;
  left: calc(50% - 3rem);
  width: 150rem;
  transform: rotate(-90deg);
  transform-origin: 0 50%;
  height: 6rem;
  z-index: 10;
  overflow: hidden;
  border-radius: 3rem;
  transition: transform $speed $evil-ease, left $speed $evil-ease, width $speed $evil-ease,
    border-radius $speed $evil-ease;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0.6;
    background: $color-foreground;
  }
}

.volume {
  cursor: pointer;
  font-size: 0;
  margin: 0;
  appearance: none;
  width: 100%;
  height: 6rem;
  border: none;
  display: block;
  position: relative;
  background: linear-gradient(
    -90deg,
    #{$accent-color} 0%,
    #{$accent-color} 50%,
    transparent 50%,
    transparent 100%
  );
  background-size: 200%;
  &::-webkit-slider-thumb {
    opacity: 0;
    background: transparent;
  }
  &:focus-visible {
    box-shadow: 0 0 0 1px red inset;
  }
}

.cursorWrapper {
  border: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.playButton {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: none;
  background: transparent;
}

.videoWrapper.mouseActive:hover {
  .progressBar {
    transform: translateY(-25rem);
    left: 40rem;
    width: calc(100% - 140rem);
    border-radius: 3rem;
    @include md {
      transform: translateY(-50rem);
      width: calc(100% - 170rem);
    }
  }
  .volumeControls {
    opacity: 1;
    transform: translate(0, 0) scale(1);
    pointer-events: all;
  }
  .controlsBackgroundOverlay {
    transform: none;
    opacity: 1;
  }
}

.describedByText {
  position: absolute;
  font-size: 2px;
  opacity: 0.001;
}
