@import '~/styles/utils';

.wrapper {
  z-index: 75;
  width: 100%;
  position: fixed;
  transition: transform $speed $evil-ease;
  transform: translateY(0);
  will-change: transform;
  top: $header-height;
}

.notAtPageTop {
  transform: translateY(-100%);
}

.scrollingUp {
  transform: translateY(0);
}

.headerNotReady {
  transform: translateY(-200%);
}

.scrollDisabled {
  transform: translateY(-200%);
}

.wellstar {
  font-weight: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: $color-white;
  // margin: $side-padding 0;
  padding: 16rem 10rem;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.1);
  // border: 1px solid $color-white;

  transition: background ease-in-out 0.3s;

  &.opacity {
    background: rgba(255, 255, 255, 0.504);
  }

  &:hover {
    border: 1px solid $color-exo-blue;
  }

  @media (min-width: 1025px) {
    p {
      max-width: 100% !important;
      font-size: 42rem !important;
    }
  }

  @media (min-width: 801px) {
    p {
      max-width: 100% !important;
      font-size: 38rem !important;
    }
  }

  @media (min-width: 600px) {
    p {
      max-width: 100% !important;
      font-size: 32rem !important;
    }
  }

  @media (min-width: 480px) {
    p {
      max-width: 400px;
      font-size: 22rem;
    }
  }

  @include landscape {
    font-size: 32rem;
    padding: 30rem 10rem;
    text-align: right;

    p {
      // max-width: 400px;
      // font-size: 22rem;
    }
  }
}

.wellstarCta {
  color: $color-black;
}

.closeButton {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  perspective: none;
  cursor: pointer;
  padding-top: 28rem;
  transform: translate(50%, -50%);
  width: 30rem;
  height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  z-index: 1;
  margin-right: 15rem;

  svg {
    width: 100%;
  }

  &:hover {
    color: $color-exo-blue;
  }
}
.irisButton {
  margin-top: 25rem;
  @include landscape {
    margin-top: 0px;
  }
}
